import { useMemo, useCallback, useReducer } from 'react';
import axios from 'axios';

import { CampaignsContext } from './campaigns-context';

// ----------------------------------------------------------------------

enum Types {
  CAMPAINGS_LIST = 'CAMPAINGS_LIST',
  CAMPAING_ITEM = 'CAMPAING_ITEM',
  CAMPAINGS_CONTACTS = 'CAMPAINGS_CONTACTS',
  USERS_LIST = 'USERS_LIST',
  USERS_LIST_REQUESTS = 'USERS_LIST_REQUESTS',
}

type CampaignsProviderProps = {
  children: React.ReactNode;
};

const initialState: any = {
  loading: true,
  campaignsList: [],
  campaignItem: null,
  campaignsContactsList: [],
  usersList: [],
  usersListRequests: [],
};

const reducer = (state: any, action: any) => {
  if (action.type === Types.USERS_LIST) {
    return {
      ...state,
      usersList: action.payload,
    };
  }
  if (action.type === Types.USERS_LIST_REQUESTS) {
    return {
      ...state,
      usersListRequests: action.payload,
    };
  }
  if (action.type === Types.CAMPAINGS_LIST) {
    return {
      ...state,
      campaignsList: action.payload.campaignsList,
    };
  }
  if (action.type === Types.CAMPAINGS_CONTACTS) {
    return {
      ...state,
      loading: false,
      campaignsContactsList: action.payload.campaignsContactsList,
    };
  }
  if (action.type === Types.CAMPAING_ITEM) {
    return {
      ...state,
      campaignItem: action.payload.campaignItem,
    };
  }

  return state;
};

export function CampaignsProvider({ children }: CampaignsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // GET CAMPAINGS
  const getCampaigns = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await axios.get(
        `https://crm-api.produktiv.agency/api/campaigns/?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.CAMPAINGS_LIST,
        payload: {
          campaignsList: data?.data,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // GET CAMPAINGS
  const getCampaignsContacts = useCallback(async (campaignId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await axios.get(
        `https://crm-api.produktiv.agency/api/campaigns/${campaignId}/contacts?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.CAMPAINGS_CONTACTS,
        payload: {
          campaignsContactsList: data,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // GET CAMPAINGS ITEM
  const getCampaignItem = useCallback(async (campaignId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await axios.get(
        `https://crm-api.produktiv.agency/api/campaigns/${campaignId}/?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.CAMPAING_ITEM,
        payload: {
          campaignItem: data?.data,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const clearCampaignDetails = useCallback(async () => {
    dispatch({
      type: Types.CAMPAING_ITEM,
      payload: {
        campaignItem: null,
      },
    });
  }, []);

  // CREATE CAMPAIGN
  const createCampaign = useCallback(
    async (data: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        await axios.post(
          `https://crm-api.produktiv.agency/api/campaigns/?cid=zxcvb`,
          { data },
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        getCampaigns();
      } catch (e) {
        console.error(e);
      }
    },
    [getCampaigns],
  );

  // UPDATE CAMPAIGN
  const updateCampaign = useCallback(async (campaignsId: any, data: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `https://crm-api.produktiv.agency/api/campaigns/${campaignsId}/?cid=zxcvb`,
        { data },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // ADD FOLLOWER
  const addFollower = useCallback(async (campaignsId: any, userId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `https://crm-api.produktiv.agency/api/campaigns/${campaignsId}/add-follower/?cid=zxcvb`,
        { data: { follower: userId } },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      getCampaignItem(campaignsId);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REMOVE FOLLOWER
  const removeFollower = useCallback(async (campaignsId: any, userId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `https://crm-api.produktiv.agency/api/campaigns/${campaignsId}/remove-follower/?cid=zxcvb`,
        { data: { follower: userId } },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      getCampaignItem(campaignsId);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // USERS LIST
  const getUsersList = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await axios.get(
        `https://api.trakt.pro/api/users/list?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.USERS_LIST,
        payload: data,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  // USERS LIST
  const getUsersRequestsList = useCallback(
    async ({ accountId, page = 1, limit = 50 }: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const res = await axios.get(
          `https://api.trakt.pro/api/requests/?cid=zxcvb&accountId=${accountId}&page=${page}&limit=${limit}`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { data } = res;

        dispatch({
          type: Types.USERS_LIST_REQUESTS,
          payload: data.data.filter(
            (el: any) =>
              el.type === 'invite_user_email' && el.status !== 'completed',
          ),
        });
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  // DELETE USER
  const deleteUser = useCallback(async (userId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.delete(
        `https://crm-api.produktiv.agency/api/users/${userId}?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // DELETE REQUEST
  const deleteRequest = useCallback(async (requestId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.delete(
        `https://crm-api.produktiv.agency/api/requests/${requestId}?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, []);

  // CHANGE ROLE USER
  const changeRoleUser = useCallback(async ({ userId, roleId }: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `https://crm-api.produktiv.agency/api/users/${userId}/change-role?cid=zxcvb`,
        { roleId },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // CHANGE USER INFO
  const changeUserInfo = useCallback(async (data: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `https://crm-api.produktiv.agency/api/users/me/settings?cid=zxcvb`,
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // CHANGE USER AVATAR
  const changeUserAvatar = useCallback(async (data: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.post(
        `https://crm-api.produktiv.agency/api/users/me/upload-profile-image?cid=zxcvb`,
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // CHANGE COMPANY AVATAR
  const changeCompanyAvatar = useCallback(async (data: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.post(
        `https://api.trakt.pro/api/account/upload-account-image?cid=zxcvb`,
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // CHANGE ACCOUNT DETAILS
  const changeAccountDetails = useCallback(async (data: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.post(
        `https://crm-api.produktiv.agency/api/account/details?cid=zxcvb`,
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // CHANGE PREFERENCES
  const changePreference = useCallback(async (data: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `https://crm-api.produktiv.agency/api/users/me/preferences?cid=zxcvb`,
        data,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);
  // ----

  const memoizedValue = useMemo(
    () => ({
      campaignsList: state.campaignsList,
      campaignItem: state.campaignItem,
      campaignsContactsList: state.campaignsContactsList,
      usersList: state.usersList,
      usersListRequests: state.usersListRequests,
      loading: state.loading,
      getCampaigns,
      getCampaignItem,
      getCampaignsContacts,
      updateCampaign,
      getUsersList,
      addFollower,
      removeFollower,
      createCampaign,
      getUsersRequestsList,
      deleteUser,
      deleteRequest,
      changeRoleUser,
      clearCampaignDetails,
      changeUserInfo,
      changePreference,
      changeUserAvatar,
      changeAccountDetails,
      changeCompanyAvatar,
    }),
    [
      state.campaignsList,
      state.campaignItem,
      state.campaignsContactsList,
      state.usersList,
      state.usersListRequests,
      state.loading,
      getCampaigns,
      getCampaignItem,
      getCampaignsContacts,
      updateCampaign,
      getUsersList,
      addFollower,
      removeFollower,
      createCampaign,
      getUsersRequestsList,
      deleteUser,
      deleteRequest,
      changeRoleUser,
      clearCampaignDetails,
      changeUserInfo,
      changePreference,
      changeUserAvatar,
      changeAccountDetails,
      changeCompanyAvatar,
    ],
  );

  return (
    <CampaignsContext.Provider value={memoizedValue}>
      {children}
    </CampaignsContext.Provider>
  );
}
